import React, { useState, useEffect } from "react";
import Sidemenu from "../sidebar/sidemenu";
import { useNavigate, useLocation } from 'react-router-dom';
import './mutualmatch.css';
import { Form, Card, FormLabel, Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import like from '../../assets/images/outlike.png';
import Toastify from 'toastify-js';
import liked from '../../assets/images/outliked.png';
import shortlist from '../../assets/images/outshortlist.png';
import shortlist1 from '../../assets/images/outshortlist1.png';
import interest from '../../assets/images/outinterest.png';
import male from '../../assets/images/male_mr.png';
import female from '../../assets/images/female_mr.png';
import male1 from '../../assets/images/men_s.svg';
import female1 from '../../assets/images/women_s.svg';
import skip from '../../assets/images/outskip.png';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import MultiRangeSlider from "multi-range-slider-react";
import axios from "axios";
import { useForm } from "react-hook-form";
import map from '../../assets/images/location.png';
import NoData from '../../assets/images/no_data.gif';
import LottieLoader3 from '../lottie/lottieloader3';
import deleteicon from '../../assets/images/delete-icon.jpg';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import clipboardCopy from 'clipboard-copy';
import interested from '../../assets/images/interested.png';
import shortlisted from '../../assets/images/shortlisted.svg';
import PaymentModel from "../Model/paymentModel";
import img from '../../assets/images/logo.svg'

function Mutualmatch() {

    const [closeaction, setcloseaction] = useState('true');
    const close = () => {
        setcloseaction('false');
    }

    const [minValue, set_minValue] = useState(25);
    const [maxValue, set_maxValue] = useState(75);
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const Auth = process.env.REACT_APP_USER_TOKEN;
    const proUrl = process.env.REACT_APP_PROFILE_LINK;
    const [loc, setLocation] = useState({ 'lat': '', 'lng': '' });
    const [show, setShow] = useState(true);
    const [right, setright] = useState(0);
    const [disable, setDisable] = useState(false);
    const [nodata, setnodata] = useState(false);
    const [usersData, setUsersData] = useState('')
    const [display_list, setdisplay] = useState({
        about_me: "", age: "", country_name: "", gender: "",
        height: "",
        height_unit: "",
        images: [{ 'image': '' }],
        like_status: "",
        locked_status: "",
        mother_tongue: "",
        name: "",
        nationality_name: "",
        occupation_name: "",
        religion_name: "",
        report_user_list_status: "",
        request_list_id: "",
        request_status: "",
        selfie_status: "",
        shortlist_status: "",
        state_name: "",
        study_name: null,
        unique_id: "",
        user_id: "",
        weight: "",
        weight_unit: "",
        photos_slider_status: "",
        display_name: ""
    })
    const location = useLocation();
    let [count, setCount] = useState(0);

    function makePageTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'block';
    }

    function makePagenotTransparent() {
        var overlay = document.getElementById('overlay');
        overlay.style.display = 'none';
    }

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [value, setValue] = useState({ 'profiles': [], 'path': '' })

    const [sliderRef] = useKeenSlider({
        mode: "free-snap",
        slides: {
            origin: "center",
            perView: 2,
            spacing: 15,
        },
    })

    const local_storage = JSON.parse(localStorage.getItem('userDetails'));
    const gender = JSON.parse(localStorage.getItem('gender'));

    const showPosition = position => {
        let loc = `Latitude:${position.coords.latitude}  logitude:
       ${position.coords.longitude}`
        setLocation({ 'lat': `${position.coords.latitude}`, 'lng': `${position.coords.longitude}` })
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            return navigator.geolocation.getCurrentPosition(showPosition);
        }
        return false
    }
    if (count === 0) {
        getLocation()
        setCount(1);
    }

    let path = 0;
    const handleClose = () => {
        setShow(false)

    };

    let list_items = []
    useEffect(() => {
        window.innerWidth < 768 ? setcloseaction('false') : setcloseaction('true');
        makePageTransparent();
        setDisable(true)
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id);
        regFormData.append('latitude', loc.lat)
        regFormData.append('longitude', loc.lng)
        regFormData.append('type', 2);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/matched_profiles`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData

        }).then((res) => {
            setDisable(false)
            const UserData = res.data.status
            if (UserData === 0) {
                setUsersData(UserData);
                makePagenotTransparent();
            }
            if (res.data.status === 1) {
                if (res.data.data.length > 0) {
                    const proFormData = new FormData();
                    proFormData.append('user_id', local_storage.user_id);
                    proFormData.append('profile_id', res.data.data[0].user_id);
                    axios({
                        method: 'POST',
                        url: `${baseUrl}/Profiles/profile_view`,
                        headers: {
                            "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                            'X-api-key': local_storage.api_key,
                        },
                        data: proFormData

                    }).then((response) => {
                        if (response.data.status === 1) {
                            setTimeout(() => {
                                setdisplay({
                                    about_me: response.data.data.about_me,
                                    age: response.data.data.age,
                                    country_name: response.data.data.country_name,
                                    gender: response.data.data.gender,
                                    height: response.data.data.height,
                                    height_unit: response.data.data.height_unit,
                                    images: response.data.data.images,
                                    like_status: response.data.data.like_status,
                                    locked_status: response.data.data.locked_status,
                                    mother_tongue: response.data.data.mother_tongue,
                                    name: response.data.data.name,
                                    display_name: response.data.data.display_name,
                                    nationality_name: response.data.data.nationality_name.length > 1 ? response.data.data.nationality_name[0] + ',' + response.data.data.nationality_name[1] : response.data.data.nationality_name[0],
                                    occupation_name: response.data.data.occupation_name,
                                    religion_name: response.data.data.religion_name,
                                    report_user_list_status: response.data.data.report_user_list_status,
                                    request_list_id: response.data.data.report_list_id,
                                    request_status: response.data.data.request_status,
                                    selfie_status: response.data.data.selfie_status,
                                    shortlist_status: response.data.data.shortlist_status,
                                    state_name: response.data.data.state_name,
                                    study_name: response.data.data.study_name,
                                    unique_id: response.data.data.unique_id,
                                    user_id: response.data.data.user_id,
                                    weight: response.data.data.weight,
                                    weight_unit: response.data.data.weight_unit,
                                    photos_slider_status: response.data.data.photos_slider_status,
                                    drink: response.data.data.drink,
                                    smoke: response.data.data.smoke,
                                    diet: response.data.data.diet,
                                })
                            }, 1000);
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                }
                let profile_list = []
                path = res.data.data.length
                if (res.data.data.length >= 1) {
                    for (let d in res.data.data) {
                        profile_list.push(res.data.data[d])
                    }
                    setTimeout(() => {
                        setValue({ 'profiles': profile_list, 'path': res.data.path });
                        makePagenotTransparent();
                    }, 1000);
                }

            } else {
                setnodata(true);
                makePagenotTransparent();
            }
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
        })
    }, [])

    const handleskip = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/skip`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles.filter((item) => item.user_id !== new_id)
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })
            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();
        }).catch((error) => {
            console.log(error)
        })
    }

    const [modalShow, setModalShow] = React.useState(false);
    const profileview = JSON.parse(localStorage.getItem('userDetails'));
    const handleinterest = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/send_request`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })
                let Imgsrc = document.getElementById(`interestImg-${new_id}`);
                if (Imgsrc) {
                    Imgsrc.src = res.data.message === 'Request sent successfully' ? `${interested}` : `${interest}`;
                }

            }
            Toastify({
                text: res.data.message,
                duration: 2000,
                newWindow: true,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                stopOnFocus: true, // Prevents dismissing of toast on hover
                style: {
                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                },
                onClick: function () { } // Callback after click
            }).showToast();
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlelike = event => {
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id);
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/user_likes`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                if (res.data.message === "Profile Liked") {
                    document.getElementById(`likes-${new_id}`).src = `${liked}`
                    document.getElementById(`dlikes-${display_list.user_id}`).src = `${liked}`
                    setTimeout(() => {
                        setdisplay({
                            ...display_list,
                            like_status: '1',
                        })
                    }, 1000)
                } else {
                    document.getElementById(`likes-${new_id}`).src = `${like}`
                    document.getElementById(`dlikes-${display_list.user_id}`).src = `${like}`
                    setTimeout(() => {
                        setdisplay({
                            ...display_list,
                            like_status: '0',
                        })
                    }, 1000)

                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    const handlesshortlist = event => {
        if (profileview.subscription_status == 0) {
            setModalShow(true);
            return false;
        }
        let id = event.currentTarget.id.split('-')
        let new_id = id[1]
        const regFormData = new FormData();
        regFormData.append('user_id', local_storage.user_id)
        regFormData.append('profile_id', new_id)

        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/shortlist`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: regFormData
        }).then((res) => {
            if (res.data.status === 1) {
                let l_items = value.profiles
                setValue({ 'profiles': l_items, 'path': value.path })
                setdisplay({ user_id: '' })
                let Imgsrc = document.getElementById(`shortlistImg-${new_id}`);
                if (Imgsrc) {
                    Imgsrc.src = res.data.message === 'Profile Shortlisted' ? `${shortlisted}` : `${shortlist}`;
                }
                Toastify({
                    text: res.data.message,
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
            }
        }).catch((error) => {
            console.log(error)
        })

    }

    const cardDisplay = event => {
        setcloseaction('true');
        makePageTransparent();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        let id = event.currentTarget.id.split('-')[1]
        const proFormData = new FormData();
        proFormData.append('user_id', local_storage.user_id);
        proFormData.append('profile_id', id)
        axios({
            method: 'POST',
            url: `${baseUrl}/Profiles/profile_view`,
            headers: {
                "Content-type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                'X-api-key': local_storage.api_key,
            },
            data: proFormData

        }).then((response) => {
            setTimeout(() => {
                setdisplay({
                    about_me: response.data.data.about_me,
                    age: response.data.data.age,
                    country_name: response.data.data.country_name,
                    gender: response.data.data.gender,
                    height: response.data.data.height,
                    height_unit: response.data.data.height_unit,
                    images: response.data.data.images,
                    like_status: response.data.data.like_status,
                    locked_status: response.data.data.locked_status,
                    mother_tongue: response.data.data.mother_tongue,
                    name: response.data.data.name,
                    display_name: response.data.data.display_name,
                    nationality_name: response.data.data.nationality_name.length > 1 ? response.data.data.nationality_name[0] + ',' + response.data.data.nationality_name[1] : response.data.data.nationality_name[0],
                    occupation_name: response.data.data.occupation_name,
                    religion_name: response.data.data.religion_name,
                    report_user_list_status: response.data.data.report_user_list_status,
                    request_list_id: response.data.data.report_list_id,
                    request_status: response.data.data.request_status,
                    selfie_status: response.data.data.selfie_status,
                    shortlist_status: response.data.data.shortlist_status,
                    state_name: response.data.data.state_name,
                    study_name: response.data.data.study_name,
                    unique_id: response.data.data.unique_id,
                    user_id: response.data.data.user_id,
                    weight: response.data.data.weight,
                    weight_unit: response.data.data.weight_unit,
                    photos_slider_status: response.data.data.photos_slider_status,
                    drink: response.data.data.drink,
                    smoke: response.data.data.smoke,
                    diet: response.data.data.diet,
                })
                makePagenotTransparent();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            makePagenotTransparent();
            setDisable(false);
        })
    }

    if (value.profiles.length !== 0) {
        list_items = value.profiles.map((item) =>

            <div className="keen-slider__slide number-slide1 d-flex flex-column justify-content-end" style={{ backgroundImage: `url(${item.selfie_status == '1' ? value.path + '/' + item.image : item.gender == '1' ? male : female})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain', borderRadius: '10px', maxWidth: '400px', height: '50vh', margin: '10px', backgroundPosition: 'center center', backgroundColor: '#c4c4c4', cursor: 'pointer' }} onClick={cardDisplay} id={`info-${item.user_id}`}>

                <div className="image-block mutualmatch_icons">
                    <button className="card-button" onClick={cardDisplay} id={`info-${item.user_id}`} style={{ outline: 'none' }}>

                        <div className="d-flex flex-column justify-content-center">
                            <div className="px-5">
                                <h5 style={{ color: 'white' }}>{item.name}, {item.age} </h5>
                                <h5 style={{ color: 'white', fontSize: 16 }}><img src={map} className="px-1" />{item.state_name != '' ? item.state_name + ',' : ''} {item.country_name}.</h5>
                            </div>
                        </div>
                    </button>

                    <div className=" d-flex my-0 mutual_bg_icons">
                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Skip"}</Tooltip>}>
                            <button className="action-buttons" id={`skip-${item.user_id}`} onClick={handleskip}><img src={skip} className="mx-2" /></button>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Interest"}</Tooltip>}>
                            <button className="action-buttons" id={`interest-${item.user_id}`} onClick={handleinterest}>
                                <img src={item.request_status == '0' ? interested : interest} className="mx-2" id={`interestImg-${item.user_id}`} />
                            </button>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Like"}</Tooltip>}>
                            <button className="action-buttons" id={`like-${item.user_id}`} onClick={handlelike}><img id={`likes-${item.user_id}`} src={item.like_status == '0' ? like : liked} className="mx-2" /></button>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip style={{ backgroundColor: "#fff", borderRadius: "5px" }}>{"Shortlist"}</Tooltip>}>
                            <button className="action-buttons" id={`shortlist-${item.user_id}`} onClick={handlesshortlist}>
                                <img id={`shortlistImg-${item.user_id}`} src={item.shortlist_status === '0' ? shortlist : shortlisted} className="mx-2" />
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>


        )
    } else {
        list_items = (
            <div className="animate" >
                {/* <img src={animation} style={{ height: '200px', width: '200px' }} /> */}
            </div>
        )
    }
    const [shareprofile, setshare] = useState(false)
    const handleopenshare = () => {
        urlShortner()
        setTimeout(() => {
            setshare(true)
        }, 1000);

    }
    const handlecloseshare = () => {
        setshare(false)
    }
    const [shorturl, setshorturl] = useState('')
    const urlShortner = async () => {
        let longurl = `${proUrl}/${local_storage.user_id}/${display_list.user_id}`
        setshorturl(longurl)
    }
    const handleClose1 = (event) => {
        event.preventDefault(); // Prevent the default link behavior (e.g., navigating to a new page)

    }
    const [linkToCopy, setLinkToCopy] = useState('');
    useEffect(() => {
        // Assuming display_list.user_id becomes available asynchronously
        if (display_list && display_list.user_id) {
            const newLink = `${proUrl}/${local_storage.user_id}/${display_list.user_id}`;
            setLinkToCopy(newLink);
        }
    }, [display_list]);
    const copyLink = () => {
        clipboardCopy(linkToCopy)
            .then(() => {
                Toastify({
                    text: "Link copied",
                    duration: 2000,
                    newWindow: true,
                    close: true,
                    gravity: "top", // `top` or `bottom`
                    position: "right", // `left`, `center` or `right`
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    },
                    onClick: function () { } // Callback after click
                }).showToast();
                // alert('Link copied to clipboard');
            })
            .catch((error) => {
                console.error('Error copying link:', error);
            });
    };
    return (
        <div className="d-lg-flex d-block">
            <PaymentModel
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            {shareprofile && shorturl != '' &&
                <div className="modal modal_blur" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-0 ">
                                <h5></h5>
                                <h5 className="modal-title">Share Profile</h5>
                                <button type="button" className="close share_close" onClick={handlecloseshare}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="text-center mb-3 mt-3">

                                <WhatsappShareButton url={shorturl}>
                                    <WhatsappIcon size={32} round={true} className="mr-3"></WhatsappIcon>
                                </WhatsappShareButton>&nbsp;
                                <FacebookShareButton url={`${baseUrl}/profile/${local_storage.user_id}/${display_list.user_id}`}>
                                    <FacebookIcon size={32} round={true} ></FacebookIcon>
                                </FacebookShareButton>
                                <OverlayTrigger overlay={<Tooltip>{"Copy link"}</Tooltip>}>
                                    <i className="fa fa-copy" onClick={copyLink} ></i>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>
                </div>

            }
            <div className="col-3 page_left" style={{ width: 'auto' }}>
                <Sidemenu />
            </div>
            <div className="col-9 d-flex flex-column mutual-page page_right">
                <div className="d-flex flex-row bg-white top-bar shadow">
                    <img src={img} className="w-50 site_logo d-block m-auto inner-logo" />
                    <div className="menu-nav">
                        <div className="dropdown-container" tabIndex="-1">
                            <div className="three-dots display_matches" id="three-dots"></div>
                            <div className="dropdown">
                                <a onClick={handleopenshare}><div>Share this profile</div></a>
                                <a href="#" onClick={handleClose1}><div className="cancel">Close</div></a>
                            </div>
                        </div>
                    </div>
                </div>
                {usersData == '0' ?
                    <div class="d-flex justify-content-center align-items-center mt-5" ><img src={NoData} /></div> : ''}
                <div className="d-flex p-0 m-0">
                    <div className="user_info col-7 mt-4 mb-3 px-1 px-sm-5 d-flex flex-column">
                        {list_items}
                    </div>
                    {list_items.length === 0 ?
                        <div className="col-5 bg-white preference_data px-5">
                            <h5 className="text-center mt-3">Partner preferences</h5>
                            <form className="mt-5">

                                <div className="d-flex justify-content-between mt-3 mb-3">
                                    <div><FormLabel>
                                        Looking for
                                    </FormLabel></div>
                                    <div>
                                        <span className="gender_3"><input type="radio" value="male" name="gender"></input><label>a man </label></span>
                                        <span className="gender_prefer">
                                            <input type="radio" value="female" name="gender"></input><label>a woman</label></span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 mb-3">
                                    <div>
                                        <FormLabel>
                                            Mother Tongue
                                        </FormLabel></div>
                                    <div>
                                        <Form.Select aria-label="Default select example" className="preference_option">
                                            <option>Telugu</option>
                                        </Form.Select></div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 mb-3">
                                    <div>
                                        <FormLabel>
                                            Studies
                                        </FormLabel>
                                    </div>
                                    <div>
                                        <Form.Select aria-label="Default select example" className="preference_option">
                                            <option>B.tech</option>
                                        </Form.Select></div>
                                </div>
                                <div className="d-flex justify-content-between mt-3 mb-3">
                                    <div>
                                        <FormLabel>
                                            Religion
                                        </FormLabel>
                                    </div>
                                    <div>
                                        <Form.Select aria-label="Default select example" className="preference_option">
                                            <option>Hindu</option>
                                        </Form.Select></div>
                                </div>
                                <Form.Select aria-label="Default select example" className="preference_option" required >
                                    <option value="" disabled selected>Country</option>


                                </Form.Select>
                                <h5 className="mt-3">Interests</h5>
                                <div className="form-check px-0" id="activeweights">


                                    <label className="custom-checkbox">
                                        <input className="getmore custom-checkbox-input" type="checkbox" />
                                        <span className="custom-checkbox-text">Fitness</span>

                                    </label>

                                </div>
                                <span className='error_msg'></span>
                                <h5 className="mt-4">Prefered age Ranges</h5>
                                <MultiRangeSlider
                                    min={18}
                                    max={90}
                                    step={1}
                                    ruler={true}
                                    label={true}
                                    preventWheel={false}
                                    minValue={minValue}
                                    maxValue={maxValue}
                                    onInput={(e) => {
                                        handleInput(e);
                                    }}
                                />
                                <div className="text-center mt-3">
                                    <button type="submit" className="btn btn-danger w-50 mb-1 reg_button">SAVE</button>
                                </div>

                            </form>
                        </div> :
                        display_list.user_id !== '' ?
                            <div id={`item-${display_list.user_id}`}>
                                {closeaction == 'true' ?
                                    <Card className="right-card profile_div" id="right-card ">
                                        {display_list.images.length > 1
                                            ?
                                            <div style={{ backgroundColor: '#c4c4c4' }}>
                                                <span style={{ visibility: 'hidden' }}> aa </span>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <div>
                                                    <Carousel>
                                                        {display_list.images.map((particular_image, imageindex) =>
                                                            <Carousel.Item key={imageindex} style={{ width: '400px', height: '100%', overflow: 'hidden', borderRadius: '0px', backgroundColor: '#c4c4c4' }}>
                                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' ? value.path + '/' + display_list.images[imageindex].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '100%', objectFit: 'contain', top: '0' }} />
                                                            </Carousel.Item>
                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ color: "#E6368B", backgroundColor: '#c4c4c4' }}>
                                                <span className="close_button float-end px-2" onClick={close} >
                                                    <img src={deleteicon} className="close-button" alt="" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
                                                </span>
                                                <Card.Img variant="top" src={`${display_list.selfie_status == '1' && display_list.images.length == '1' ? value.path + '/' + display_list.images[0].image : display_list.gender == '1' ? male1 : female1}`} style={{ width: '100%', height: '300px', top: '0', backgroundColor: '#c4c4c4', objectFit: 'contain' }} />
                                            </div>
                                        }
                                        <Card.Body>
                                            <Card.Title><p style={{ fontWeight: 500 }}>{display_list.display_name}, {display_list.age}, {display_list.gender == 1 ? "Male" : "Female"} <span style={{ color: '#E6368B' }}>(#{display_list.unique_id})</span></p>
                                                <p><img src={map} className="px-1" />{display_list.state_name ? `${display_list.state_name},` : ''} {display_list.country_name}.</p>
                                                {display_list.request_status == '4' ? <span style={{ color: '#E6368B' }}>{display_list.display_name} sent you a request</span> : ''}

                                                <p style={{ fontWeight: 500 }} className="mt-2">About me</p>
                                                <p>{display_list.about_me}</p>
                                            </Card.Title>

                                            <Card.Text>
                                                <div className="profile_data">
                                                    <div><span>Languages</span></div>
                                                    <div><span>{display_list.mother_tongue}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Nationality</span></div>
                                                    <div><span>{display_list.nationality_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Country</span></div>
                                                    <div><span>{display_list.country_name}</span></div>
                                                </div>
                                                {display_list.state_name && (
                                                    <div className="profile_data">
                                                        <div><span>State</span></div>
                                                        <div><span>{display_list.state_name}</span></div>
                                                    </div>
                                                )}

                                                <div className="profile_data">
                                                    <div><span>Occupation</span></div>
                                                    <div><span>{display_list.occupation_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Height</span></div>
                                                    <div><span>{display_list.height} {display_list.height_unit}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Weight</span></div>
                                                    <div><span>{display_list.weight}</span></div>
                                                </div>
                                                {display_list.study_name &&
                                                    <div className="profile_data">
                                                        <div><span>Education</span></div>
                                                        <div><span>{display_list.study_name}</span></div>
                                                    </div>}
                                                <div className="profile_data">
                                                    <div><span>Religion</span></div>
                                                    <div><span>{display_list.religion_name}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Smoke</span></div>
                                                    <div><span>{display_list.smoke}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Drink</span></div>
                                                    <div><span>{display_list.drink}</span></div>
                                                </div>
                                                <div className="profile_data">
                                                    <div><span>Diet</span></div>
                                                    <div><span>{display_list.diet}</span></div>
                                                </div>
                                                <hr className="m-0 p-0" style={{ color: "#E6368B", backgroundColor: "#E6368B" }}>
                                                </hr>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="m-auto">
                                            <OverlayTrigger overlay={<Tooltip>{"Interest"}</Tooltip>}>
                                                <button className="action-buttons" id={`dinterest-${display_list.user_id}`} onClick={handleinterest}><img src={display_list.request_status == '0' ? interested : interest} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Like"}</Tooltip>}>
                                                <button className="action-buttons" id={`dlike-${display_list.user_id}`} onClick={handlelike}><img id={`dlikes-${display_list.user_id}`} src={display_list.like_status == '0' ? like : liked} className="mx-2" /></button>
                                            </OverlayTrigger>

                                            <OverlayTrigger overlay={<Tooltip>{"Shortlist"}</Tooltip>}>
                                                <button className="action-buttons" id={`dshortlist-${display_list.user_id}`} onClick={handlesshortlist}><img src={shortlist1} className="mx-2" /></button>
                                            </OverlayTrigger>
                                        </Card.Footer>
                                    </Card> : ""}
                            </div> : ''
                    }

                </div >
            </div>
            <div id="overlay">
                <div className='AddloadingImage'>
                    <LottieLoader3 />
                </div>
            </div>
        </div>
    )
}

export default Mutualmatch;