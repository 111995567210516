import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAGib4cFp24W08rwMlumrpILmwGObK1bMs",
  authDomain: "redated-5161c.firebaseapp.com",
  projectId: "redated-5161c",
  storageBucket: "redated-5161c.firebasestorage.app",
  messagingSenderId: "70014407763",
  appId: "1:70014407763:web:7191507ae7ef2c14b0a2a6",
  measurementId: "G-Q3C98VGGHF"
};

const firebase = initializeApp(firebaseConfig);
const db = getDatabase(firebase);

export {db};

